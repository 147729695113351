import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PricingTemplate, PricingTemplateCard } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'debit-pricing',
  templateUrl: './debit-pricing-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DebitPricingCardComponent implements OnInit {
  @Input() showCard?: boolean = true;
  @Input() label?: string = 'PRICING DETAILS';
  @Input() currency?: PricingTemplate.CurrencyEnum = PricingTemplate.CurrencyEnum.USD;
  @Input() feeType?: PricingTemplateCard.TransactionFeeTypeEnum;
  @Input() transactionFee?: number = 0;
  @Input() returnFee?: number = 0;
  @Input() accountMonthlyFee?: number = 0;
  @Input() accountMonthlyMinimumFee?: number = 0;

  constructor() {}

  ngOnInit() {}
}
