import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { ApplicationStep } from 'app/core/models/application-step';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { BusinessLegalEntity, MerchantApplication } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'processing-volumes-merchant-step',
  templateUrl: './processing-volumes-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingVolumesMerchantStepComponent extends ComponentBase implements OnInit, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  public processingVolumesForm: FormGroup;
  public steps: ApplicationStep[];
  public merchantApp: MerchantApplication;

  public volumeRanges: Array<any> = [
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.LOW,
      viewValue: 'Less than 50k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.MEDIUM,
      viewValue: '50k–100k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.HIGH,
      viewValue: '100k–250k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.VERY_HIGH,
      viewValue: '250k or more',
    },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
  ) {
    super();
  }

  ngOnInit(): void {
    const avgMonthlyVolume =
      (this.merchantApp?.business_legal_entity?.average_transaction_amount / 100) *
      this.merchantApp?.business_legal_entity?.average_transactions_per_month;

    this.processingVolumesForm = this._formBuilder.group({
      annualProcessingVolume: new FormControl(this.merchantApp?.business_legal_entity?.yearly_volume_range || null),
      averageTransactionAmount: new FormControl(
        this.merchantApp?.business_legal_entity?.average_transaction_amount
          ? this.merchantApp?.business_legal_entity?.average_transaction_amount / 100
          : null,
      ),
      averageMonthlyTransactions: new FormControl(
        this.merchantApp?.business_legal_entity?.average_transactions_per_month || null,
      ),
      averageMonthlyVolume: new FormControl({ value: avgMonthlyVolume, disabled: true }),
    });

    this._merchantAppService.merchantAppSteps$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((steps) => (this.steps = steps));

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.processingVolumesForm.disable();
          } else {
            this.processingVolumesForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(1);
  }

  onContinueClicked(accountId?: string) {
    if (this.processingVolumesForm.invalid) {
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.merchantApp.business_legal_entity.average_transaction_amount = this.processingVolumesForm.value
      .averageTransactionAmount
      ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmount)
      : null;
    this.merchantApp.business_legal_entity.yearly_volume_range =
      this.processingVolumesForm.value.annualProcessingVolume;
    this.merchantApp.business_legal_entity.average_transactions_per_month =
      this.processingVolumesForm.value.averageMonthlyTransactions;

    this._merchantAppService.updateMerchantApplication(this.merchantApp, 3, accountId);
  }

  private isAppUnsaved(): boolean {
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    return !(this.merchantApp.business_legal_entity?.average_transaction_amount
      ? this.merchantApp.business_legal_entity?.average_transaction_amount / 100
      : null == this.processingVolumesForm.value.averageTransactionAmount &&
        _compareTwoStrings(
          this.merchantApp.business_legal_entity?.yearly_volume_range,
          this.processingVolumesForm.value.annualProcessingVolume,
        ) &&
        this.merchantApp.business_legal_entity?.average_transactions_per_month ==
          this.processingVolumesForm.value.averageMonthlyTransactions);
  }

  private resetApplication(): void {
    this.processingVolumesForm.controls['averageTransactionAmount'].setValue(
      this.merchantApp.business_legal_entity?.average_transaction_amount
        ? this.merchantApp.business_legal_entity?.average_transaction_amount / 100
        : null,
    );
    this.processingVolumesForm.controls['annualProcessingVolume'].setValue(
      this.merchantApp.business_legal_entity?.yearly_volume_range,
    );
    this.processingVolumesForm.controls['averageMonthlyTransactions'].setValue(
      this.merchantApp.business_legal_entity?.average_transactions_per_month,
    );

    const avgMonthlyVolume =
      (this.merchantApp?.business_legal_entity?.average_transaction_amount / 100) *
      this.merchantApp?.business_legal_entity?.average_transactions_per_month;

    this.processingVolumesForm.controls['averageMonthlyVolume'].setValue(avgMonthlyVolume);
  }
}
