<ng-container *ngIf="groupName" [formGroupName]="groupName">
  <mat-form-field [appearance]="appearance" class="w-full overflow-visible" [hideRequiredMarker]="hideRequiredMarker">
    <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
    <tilled-label-l1 *ngIf="tilledLabel">{{ label }}</tilled-label-l1>
    <span *ngIf="showPercent" class="{{ styles }}" matSuffix>%</span>
    <span matPrefix *ngIf="showCurrency" class="{{ styles }}">$&nbsp;</span>
    <input
      type="{{ fieldType }}"
      [formControlName]="name"
      matInput
      placeholder="{{ placeholder }}"
      mask="{{ mask }}"
      [dropSpecialCharacters]="dropMaskCharacters"
      [leadZeroDateTime]="leadZeroDateTime"
      thousandSeparator="{{ thousandSeparator }}"
      [readonly]="readonly"
      minlength="{{ minLength }}"
      maxlength="{{ maxLength }}"
    />
    <mat-error color="warn" *ngIf="errors">{{ errorMessage }}</mat-error>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="!groupName">
  <mat-form-field [appearance]="appearance" class="w-full overflow-visible" [hideRequiredMarker]="hideRequiredMarker">
    <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
    <div *ngIf="tilledLabel" class="mb-4 flex flex-row items-center">
      <tilled-label-l1 [secondary]="true">{{ label }}</tilled-label-l1>
      <mat-icon
        *ngIf="toolTip"
        class="ml-2 icon-size-4"
        [svgIcon]="'heroicons_solid:information-circle'"
        matTooltip="{{ toolTip }}"
      ></mat-icon>
    </div>
    <span *ngIf="showPercent" class="{{ styles }}" matSuffix>%</span>
    <span matPrefix *ngIf="showCurrency" class="{{ styles }}">$&nbsp;</span>
    <div class="flex">
      <span *ngIf="prefix" class="pr-0.5">{{prefix}}</span>
      <input
        [class]="tilledLabel ? 'text-tilled-primary' : styles"
        type="{{ fieldType }}"
        [formControlName]="name"
        matInput
        placeholder="{{ placeholder }}"
        mask="{{ mask }}"
        [dropSpecialCharacters]="dropMaskCharacters"
        [leadZeroDateTime]="leadZeroDateTime"
        thousandSeparator="{{ thousandSeparator }}"
        [readonly]="readonly"
        minlength="{{ minLength }}"
        maxlength="{{ maxLength }}"
        min="{{ minValue }}"
        required="{{ required }}"
      />
      <span *ngIf="suffix">{{suffix}}</span>
    </div>
    <mat-error color="warn" *ngIf="errors">{{ errorMessage }}</mat-error>
  </mat-form-field>
</ng-container>
