/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface BusinessRepresentative { 
    addresses: Array<Address>;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The email address of the representative.
     */
    email?: string;
    /**
     * The representative\'s first name.
     */
    first_name?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * If this associate is the one completing the application.
     */
    is_applicant: boolean;
    /**
     * If this associate has a controlling interest.
     */
    is_control_prong: boolean;
    /**
     * The representative\'s title at the company.
     */
    job_title: string;
    /**
     * The representative\'s last name.
     */
    last_name?: string;
    /**
     * The representative\'s middle name.
     */
    middle_name?: string;
    /**
     * The phone number for the representative.
     */
    phone: string;
    /**
     * Type of representative. Ex. shareholder, director, officer, merchant rep
     */
    type: BusinessRepresentative.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace BusinessRepresentative {
    export type TypeEnum = 'director' | 'shareholder' | 'merchant_rep' | 'contact';
    export const TypeEnum = {
        DIRECTOR: 'director' as TypeEnum,
        SHAREHOLDER: 'shareholder' as TypeEnum,
        MERCHANT_REP: 'merchant_rep' as TypeEnum,
        CONTACT: 'contact' as TypeEnum
    };
}


