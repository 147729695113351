import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AccountCapability, InternalAccount } from '@tilled-api-client';
import { HOMEPAGE_ROUTE } from 'app/core/constants';
import { AppUser } from 'app/core/data/auth-types';
import { AuthService } from 'app/core/services/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'onboarding-layout',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingLayoutComponent implements OnInit, OnDestroy {
  public isMultiAccount = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private _currentAccountId: string = null;

  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Subscribe to the user service
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: AppUser) => {
      this.isMultiAccount = user.account_roles?.length > 1;
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      this.handleAccountUpdate(account);
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private handleAccountUpdate(account: InternalAccount): void {
    const currentUrl = this._router.url;

    let urlToNavigateTo: string;

    if (account.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
      urlToNavigateTo = HOMEPAGE_ROUTE;
    } else if (
      account.capabilities.find((cap) =>
        [
          AccountCapability.StatusEnum.SUBMITTED,
          AccountCapability.StatusEnum.REJECTED,
          AccountCapability.StatusEnum.WITHDRAWN,
          AccountCapability.StatusEnum.IN_REVIEW,
        ].includes(cap.status),
      )
    ) {
      // redirect to submitted page.
      urlToNavigateTo = '/onboarding/submitted';
    } else if (account.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.CREATED)) {
      urlToNavigateTo = '/onboarding/get-started';
    } else {
      // Authorized user that has not yet completed a merchant application
      // Redirect to merch app.
      urlToNavigateTo = '/onboarding/application';
    }

    if (currentUrl !== urlToNavigateTo) {
      this._router.navigate([urlToNavigateTo]);
    }
  }
}
