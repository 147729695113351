import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { PaymentStatusPipe } from 'app/core/pipes/payment-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { RefundsAppService } from 'app/core/services/refunds.app.service';
import { RefundFormDialogComponent } from 'app/modules/payments/refund-form-dialog/refund-form-dialog.component';
import moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  GetPaymentIntentRequestParams,
  InternalListPaymentIntentsRequestParams,
  Refund,
} from '../../../../projects/tilled-api-client/src';
import { InternalPaymentIntent } from '../../../../projects/tilled-api-client/src/model/internalPaymentIntent';
import { PaymentsTypes } from '../../core/data/payments-types';
import { ActionListItem } from '../action-list/action-list.model';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() isMerchant: boolean;
  @Input() initialQuery: string = null;
  @Input() query: string = null;
  @Input() queryMerchantId: string = null;
  @Input() initialStartDate: string = null;
  @Input() initialEndDate: string = null;
  @Input() startDate: string = null;
  @Input() endDate: string = null;
  @Input() customerId: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() updateQueryCallback: (
    q?: string,
    startDate?: string,
    endDate?: string,
    queryMerchantId?: string,
    page?: number,
    size?: number,
  ) => void;

  public secondaryReasonText = 'Process a payment to see payment data.';
  public dialogRef: any;
  public updatedPayment$: Observable<InternalPaymentIntent>;
  public paymentsViewModel$: Observable<PaymentViewModel[]>;
  public currentlyRefundedPayment: InternalPaymentIntent;
  public refund$: Observable<Refund>;
  public refundCreated$: Observable<boolean>;
  public paymentsCount$: Observable<number>;
  public selectedPayment: InternalPaymentIntent;
  public selectedActionList: ActionListItem[];
  public hideColumns: number[] = [5];
  public isLoading = true;
  public sortInfo = null;

  constructor(
    private _paymentAppService: PaymentAppService,
    private _paymentStatusPipe: PaymentStatusPipe,
    private _dateFormatPipe: DateFormatPipe,
    private _refundsAppService: RefundsAppService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _authService: AuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.getPaymentIntents(this.pageSize, this.pageIndex);
    if (this.isMerchant) {
      this.hideColumns.push(1);
    }

    this._paymentAppService.payments$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((result) => {
          this.paymentsViewModel$ = of(this.getViewModelsFromPayments(result));
          if (this.updateQueryCallback) {
            this.updateQueryCallback(
              this.query,
              this.startDate,
              this.endDate,
              this.queryMerchantId,
              this.pageIndex,
              this.pageSize,
            );
          }
        }),
      )
      .subscribe();
    this.paymentsCount$ = this._paymentAppService.paymentsCount$;

    this.updatedPayment$ = this._paymentAppService.payment$;
    this.updatedPayment$.pipe(takeUntil(this._unsubscribeAll)).subscribe((payment) => {
      this.selectedPayment = payment;
      this.paymentsViewModel$ = this.paymentsViewModel$.pipe(
        map((paymentViewModels) =>
          paymentViewModels.map((paymentViewModel) => {
            if (paymentViewModel.id !== payment.id) {
              return paymentViewModel;
            } else {
              return this.convertPaymentToViewModel(payment, this.isMerchant);
            }
          }),
        ),
      );
    });

    this.refund$ = this._refundsAppService.refund$;
    this.refundCreated$ = this._refundsAppService.refundCreated$;

    this.refundCreated$
      .pipe(
        tap((bool) => {
          this.updatePaymentIntentFromRefund(bool);
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  // onChange fires before onInit so we want to load he payment intents just once here on
  // page load.
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.query && !changes.query.firstChange) ||
      (changes.queryMerchantId && !changes.queryMerchantId.firstChange) ||
      (changes.startDate && !changes.startDate.firstChange) ||
      (changes.endDate && !changes.endDate.firstChange) ||
      (changes.accountId && !changes.accountId.firstChange)
    ) {
      this.getPaymentIntents(this.pageSize, 0);
    }
  }

  updatePaymentIntentFromRefund = (bool: boolean): void => {
    if (bool) {
      const params: GetPaymentIntentRequestParams = {
        tilledAccount: this.currentlyRefundedPayment.account_id,
        id: this.currentlyRefundedPayment.id,
      };
      this._paymentAppService.getPaymentIntent(params);
    }
  };

  getPaymentIntents = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    const params: InternalListPaymentIntentsRequestParams = {
      tilledAccount: this.queryMerchantId ?? this.accountId,
      createdAtGte: this.startDate ? new Date(this.startDate).toString() : null,
      createdAtLte: this.endDate ? new Date(this.endDate).toString() : null,
      //status: status,
      includeConnectedAccounts: !this.isMerchant,
      offset: size * index,
      limit: size,
      customerId: this.customerId,
      q: this.query,
    };

    this._paymentAppService.getAllPaymentIntents(params);

    if (this.query || this.queryMerchantId || this.startDate || this.endDate) {
      this.secondaryReasonText = 'Update the filters to see results.';
    } else {
      this.secondaryReasonText = 'Process a payment to see payment data.';
    }
  };

  convertPaymentToViewModel(payment: InternalPaymentIntent, isMerchant: boolean): PaymentViewModel {
    const temp: PaymentViewModel = new PaymentViewModel();
    temp.payment = payment;
    temp.created_at = this._dateFormatPipe.transform(payment.created_at);
    temp.id = payment.id;
    temp.merchant_id = payment.account_id;
    temp.merchant_name = payment.account_name;
    temp.cardholder = payment.payment_method?.card
      ? payment.payment_method?.card?.holder_name
        ? payment.payment_method?.card?.holder_name
        : '-'
      : payment.payment_method?.billing_details?.name
      ? payment.payment_method?.billing_details?.name
      : '-';
    if (payment.payment_method?.ach_debit) {
      temp.last_4 = '**' + payment.payment_method.ach_debit.last2;
    } else if (payment.payment_method?.card) {
      temp.last_4 = payment.payment_method.card.last4;
    }
    temp.transaction_id = payment.id;
    temp.currency = new UpperCasePipe().transform(payment.currency);
    temp.amount = payment.amount;
    temp.status = payment.status;
    temp.chipConfig = this._paymentStatusPipe.transform(payment, false);
    temp.action =
      !payment.charges.find((c) => c.status === 'succeeded') ||
      !this._authService.isScopeAble('refunds:write') ||
      (temp.chipConfig.text !== 'SUCCEEDED' && temp.chipConfig.text !== 'PARTIAL REFUND')
        ? []
        : [
            {
              name: 'Initiate refund',
              callback: (): void => {
                this.createRefund(payment);
              },
              disabled: false,
            },
          ];
    temp.action = temp.action.concat([
      {
        name: 'View payment details',
        callback: (): void => {
          this._router.navigate([`/${payment.account_id}/payments/${payment.id}`]);
        },
        disabled: false,
      },
    ]);
    if (!isMerchant) {
      temp.action = temp.action.concat([
        {
          name: 'View merchant',
          callback: (): void => {
            this._router.navigate([`/merchants/${payment.account_id}`]);
          },
          disabled: false,
        },
      ]);
    }

    temp.transaction_type = PaymentsTypes.PaymentMethodDisplayText.get(payment.payment_method?.type);

    return temp;
  }

  getViewModelsFromPayments(payments: InternalPaymentIntent[]): PaymentViewModel[] {
    const viewModels: PaymentViewModel[] = [];
    if (!payments || payments.length === 0) {
      const temp: PaymentViewModel = new PaymentViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const payment of payments) {
      let viewModel = this.convertPaymentToViewModel(payment, this.isMerchant);

      viewModels.push(viewModel);
    }
    return viewModels;
  }

  rowClickedCallback = (data: PaymentViewModel, event?: MouseEvent): void => {
    const url = `${data.merchant_id}/payments/${data.id}`;

    // Open a new window (Shift) or tab (Ctrl/Option)
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
      window.open(url, event?.shiftKey ? '_blank' : undefined);
    } else {
      this._router.navigate([url]);
    }
  };

  public createRefund = (payment: InternalPaymentIntent): void => {
    this.currentlyRefundedPayment = payment;
    this.dialogRef = this._matDialog.open(RefundFormDialogComponent, {
      panelClass: 'refund-dialog',
      disableClose: true,
      width: '550px',
      data: {
        action: 'new',
        id: payment.id,
        accountId: payment.account_id,
        payment: payment,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {});
  };
}

export class PaymentViewModel {
  payment: InternalPaymentIntent;

  id: string;

  merchant_id: string;

  @Column({
    order: 0,
    name: 'Transaction Date',
    styling: 'min-width:210px',
    cellTooltip: (dateString: string) => {
      const date = moment(dateString, 'LLL');
      const local = date.local().format('llll');
      const eastern = date.tz('America/New_York').format('llll');

      return `
            Local Time: ${local}; \n Eastern Time: ${eastern};
            `;
    },
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Merchant',
    styling: 'min-width:200px; max-width:250px;',
  })
  merchant_name: string;

  @Column({ order: 2, name: 'Payment Method', styling: 'min-width:100px' })
  transaction_type: string;

  @Column({
    order: 3,
    name: 'Cardholder',
    styling: 'min-width:120px',
    headerTooltip: 'If the payment method type is direct debit, the billing name is shown.',
  })
  cardholder: string;

  @Column({
    order: 4,
    name: 'Number',
    styling: 'min-width:80px; max-width:100px;',
    headerTooltip:
      'The number reflects the last 4 digits of a card number or the last 2 digits of a bank account number.',
  })
  last_4: string;

  @Column({
    order: 5,
    name: 'Transaction Id',
    styling: 'min-width:225px',
  })
  transaction_id: string;

  // displayed, but also needed to display currency of amount
  @Column({
    order: 5,
    name: 'Currency',
    styling: 'min-width:70px',
  })
  currency: string;

  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;

  @Column({
    order: 6,
    name: 'Status',
    isChip: true,
    styling: 'min-width:140px',
  })
  status: string;

  @Column({
    order: 7,
    name: 'Amount',
    isCurrency: true,
    styling: 'min-width:100px; max-width:120px;',
    headerTooltip: 'The amount reflects the original charge amount.',
  })
  amount: number;

  @Column({
    order: 8,
    isActionList: true,
  })
  action: ActionListItem[];
}
