/* eslint-disable @typescript-eslint/member-ordering */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {
  AccountRole,
  ApiKey,
  AuthLinkLoginDto,
  ForgotPasswordParams,
  ForgotPasswordRequestParams,
  GetUserRequestParams,
  InternalAccount,
  InternalRedeemAuthLinkRequestParams,
  InternalService,
  LoginDto,
  LoginRequestParams,
  RefreshAccessTokenRequestParams,
  RegisterParams,
  RegisterRequestParams,
  ResetPasswordRequestParams,
  User,
  UserResetPasswordParams,
  UsersService,
} from '@tilled-api-client';
import { AUTH_LOGIN_ROUTE } from 'app/core/constants';
import { StatusCodes } from 'http-status-codes';
import jwt_decode from 'jwt-decode';
import { cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  share,
  shareReplay,
  skipWhile,
  switchMap,
  throwError,
} from 'rxjs';
import { AppUser, JwtTokenData } from '../data/auth-types';

const TOKEN_STORAGE_KEY = 'tilled-token'; // 'tilled-access-token'
const REFRESH_TOKEN_STORAGE_KEY = 'tilled-refresh-token';
const CURRENT_ACCOUNT_ID_STORAGE_KEY = 'tilled-account-id';

declare let pendo; //: pendo.Pendo; // cannot find namespace 'pendo'. Uncomment for types.

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _decodedAccessToken?: JwtTokenData;
  private _appUser: BehaviorSubject<JwtTokenData | null> = new BehaviorSubject<JwtTokenData | null>(null);

  public isMerchantAccount$: Observable<boolean>;

  constructor(private _usersService: UsersService, private _internalService: InternalService, private _router: Router) {
    this.handleNewAccessToken(AuthService.getAccessToken());

    this.isMerchantAccount$ = this.user$.pipe(
      map((user) => {
        // Currently, multi-account users ONLY are associated with merchant accounts. If we allow mixed account types,
        // we'll need to look at the currentAccount
        const merchantAccountRole = user?.account_roles?.find((ar) =>
          [User.RoleEnum.MERCHANT_OWNER, User.RoleEnum.MERCHANT_ADMIN].includes(ar.role),
        );
        return !!merchantAccountRole;
      }),
    );
    this.accounts$.subscribe({
      next: (accts) => {
        this.handleAccountsApiResponse(accts);
      },
      error: (err) => {
        this.reset();
      },
    });
  }

  get user$(): Observable<AppUser | null> {
    return this._appUser.asObservable();
  }

  get user(): AppUser | null {
    return this._appUser.getValue();
  }

  private handleAccountsApiResponse(accounts: InternalAccount[]): void {
    if (accounts.length === 0) {
      this.reset();
      return;
    }

    // For multi-account users, we should retain a "last account"
    // that was accessed in the browser.
    // Whenever _accounts is updated, we need to ensure that currentAccount/account$ is updated
    const currentAccountId = AuthService.getCurrentAccountId();

    let updatedAccount: InternalAccount;
    if (currentAccountId) {
      updatedAccount = accounts?.find((a) => a.id === currentAccountId);
    }

    if (!updatedAccount) {
      updatedAccount = accounts[0];
    }

    this.setCurrentAccountId(updatedAccount.id);
    this.configurePendo(this._decodedAccessToken, updatedAccount);
  }

  private _updateAccount$ = new BehaviorSubject<void>(undefined);

  // Used to cache result of "listMyAccounts"
  private _reloadAccounts$ = new BehaviorSubject<void>(undefined);

  /**
   * Causes _internalService.listMyAccounts() to actually make an API request.
   * Which will in turn cause accounts$ and account$ to emit.
   */
  public reloadAccounts(): void {
    this._reloadAccounts$.next();
  }

  // private updateAccount(): void {
  //   this._updateAccount$.next();
  // }
  /**
   * Returns all accounts that current user has access to.
   *
   * Notes:
   * shareReplay will ensure that "listMyAccounts" is only called _once_ ever. Which is great for caching.
   * If you want to force a reload at some point, use 'reloadAccounts'.
   *
   * All accounts$ subscribers will not have to re-subscribe after refresh.
   */
  public accounts$: Observable<InternalAccount[]> = this._reloadAccounts$.pipe(
    skipWhile(() => !AuthService.getAccessToken()),
    switchMap(() => this._internalService.listMyAccounts()),
    shareReplay(1),
  );

  public account$: Observable<InternalAccount> = this._updateAccount$.pipe(
    switchMap(() => this.accounts$),
    skipWhile((accounts) => {
      const currentAccountId = AuthService.getCurrentAccountId();
      const account = accounts?.find((a) => a.id === currentAccountId);
      return account == null;
    }),
    map((accts) => {
      const currentAccountId = AuthService.getCurrentAccountId();
      const account = accts?.find((a) => a.id === currentAccountId);
      return account;
    }),
  );

  /**
   * This is the method that is called when the multi-account selector
   * changes the selected account.
   */
  setCurrentAccountId(accountId: string): void {
    if (accountId) {
      localStorage.setItem(CURRENT_ACCOUNT_ID_STORAGE_KEY, accountId);
      sessionStorage.setItem(CURRENT_ACCOUNT_ID_STORAGE_KEY, accountId);

      this._updateAccount$.next();
    } else {
      localStorage.removeItem(CURRENT_ACCOUNT_ID_STORAGE_KEY);
      sessionStorage.removeItem(CURRENT_ACCOUNT_ID_STORAGE_KEY);
    }
  }

  private setUser(token: JwtTokenData | null): void {
    // Only place we set the user.
    this._appUser.next(token);

    if (token) {
      Sentry.setUser({
        id: token.id,
      });
    } else {
      Sentry.setUser(null);
    }
  }

  /**
   * Retrieve the Tilled API access_token out of session or local storage.
   */
  public static getAccessToken(): string {
    return this.getStorageItemByKey(TOKEN_STORAGE_KEY);
  }

  /**
   * Retrieve the Tilled API refresh_token out of session or local storage.
   */
  public static getRefreshToken(): string {
    return this.getStorageItemByKey(REFRESH_TOKEN_STORAGE_KEY);
  }

  public static getCurrentAccountId(): string {
    return this.getStorageItemByKey(CURRENT_ACCOUNT_ID_STORAGE_KEY);
  }

  private static getStorageItemByKey(key: string): string {
    return sessionStorage.getItem(key) ?? localStorage.getItem(key);
  }

  // If we're storing the refresh token in local storage then rememberMe was true
  private isRememberMe(): boolean {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) != null;
  }

  public redeemAuthLink(id: string): Observable<AuthLinkLoginDto> {
    const params: InternalRedeemAuthLinkRequestParams = {
      id: id,
    };

    return this._internalService.internalRedeemAuthLink(params).pipe(
      switchMap((result) => {
        if (result.credentials) {
          this.reset();
          this.setRefreshToken(result.credentials.refresh_token, true);
          this.setAccessToken(result.credentials.token, true);
        }
        return of(result);
      }),
      catchError((err) => throwError(() => new Error(err))),
      share(),
    );
  }

  login(body: { email: string; password: string }, rememberMe = false): Observable<void> {
    const requestParams: LoginRequestParams = {
      loginParams: body,
    };
    return this._usersService.login(requestParams).pipe(
      map((result) => {
        this.reset();
        this.setRefreshToken(result.refresh_token, rememberMe);
        this.setAccessToken(result.token, rememberMe);
      }),
    );
  }

  /**
   * This is used for impersonation primarily. We don't use refresh tokens here.
   */
  loginWithToken(token: string, rememberMe = false): Observable<void> {
    // Attempt to decode the token first, then make an API call to fetch the user
    // if that passes, then the token is valid.
    return this.isTokenValid(token).pipe(
      map((isValid) => {
        // Reset no matter what?
        this.reset();

        if (isValid) {
          this.setAccessToken(token, rememberMe);
        } else {
          throw new HttpErrorResponse({
            error: new Error('Invalid token'),
            status: StatusCodes.UNAUTHORIZED,
          });
        }
      }),
    );
  }

  refreshAccessToken(): Observable<LoginDto> {
    const requestParams: RefreshAccessTokenRequestParams = {
      accessTokenRefreshParams: {
        refresh_token: AuthService.getRefreshToken(),
      },
    };
    return this._usersService.refreshAccessToken(requestParams).pipe(
      switchMap((response: LoginDto) => {
        const rememberMe = this.isRememberMe();
        this.reset(false);
        this.setRefreshToken(response.refresh_token, rememberMe);
        this.setAccessToken(response.token, rememberMe);
        return of(response);
      }),
      catchError((err) => {
        this.reset();
        return throwError(() => new Error(err));
      }),
    );
  }

  // Creates user and 'partner' account
  register(body: RegisterParams): Observable<void> {
    const requestParams: RegisterRequestParams = {
      registerParams: body,
    };
    return this._usersService.register(requestParams).pipe(
      map((result) => {
        this.reset();
        this.setAccessToken(result.token, false);
        // TODO: Possibly include refresh_token during registration.
        //this.setRefreshToken(result.refresh_token, false);
      }),
    );
  }

  forgotPassword(body: ForgotPasswordParams): Observable<void> {
    const requestParams: ForgotPasswordRequestParams = {
      forgotPasswordParams: body,
    };
    return this._usersService.forgotPassword(requestParams);
  }

  resetPassword(body: UserResetPasswordParams): Observable<void> {
    const requestParams: ResetPasswordRequestParams = {
      userResetPasswordParams: body,
    };
    return this._usersService.resetPassword(requestParams);
  }

  logout(redirect = false): void {
    if (!this.isRefreshTokenExpired()) {
      this._usersService.logout().subscribe({
        next: (res) => {
          this.reset();
        },
        error: (error) => {
          this.reset();
        },
      });
    } else {
      this.reset();
    }

    if (redirect) {
      this._router.navigate(['/' + AUTH_LOGIN_ROUTE]);
    }
  }

  public setAccessToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
    } else {
      sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
    }

    this.handleNewAccessToken(token);
  }

  public setRefreshToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
    } else {
      sessionStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
    }
  }

  /**
   * Updates user/access token and reloads user accounts
   */
  private handleNewAccessToken(accessToken: string): void {
    if (accessToken) {
      try {
        this._decodedAccessToken = jwt_decode<JwtTokenData>(accessToken);
        if (this._decodedAccessToken) {
          const tmp = cloneDeep(this._decodedAccessToken);
          delete tmp.iat;
          delete tmp.jti;
          delete tmp.exp;
          this.setUser(tmp);

          this.reloadAccounts();

          const currentAccountId = AuthService.getCurrentAccountId();
          if (!currentAccountId) {
            let userAccountId: string;
            if (tmp.account_roles?.length > 0) {
              userAccountId = tmp.account_roles[0].account_id;
            } else {
              userAccountId = tmp.account_id;
            }
            this.setCurrentAccountId(userAccountId);
          }
        } else {
          this.setUser(null);
        }
      } catch (error) {
        this.reset();
      }
    } else {
      this.reset();
    }
  }

  private configurePendo(user: JwtTokenData, account: InternalAccount): void {
    if (!user?.id || !account?.id) {
      return;
    }

    let visitorId = user.id;
    if (user.impersonated_by) {
      // Pendo said adding a metadata tag isn't a great idea because it can be overwritten
      // and the analytics tool pulls the most recent data associated with that visitor.
      // Instead, they agreed that manipulating the visitor id was the best bet for
      // determining whether a user was being impersonated
      visitorId = `${user.id}_impersonated_by_${user.impersonated_by}`;
    }
    const userAccountRole = user.account_roles.find((ar) => ar.account_id === account.id)?.role;

    const pendoVisitor = {
      id: visitorId,
      email: user.email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      full_name: user.name,
      role: userAccountRole,
    };

    const pendoAccount = {
      id: account.id,
      name: account.name,
    };

    if (pendo.isReady()) {
      pendo.identify({ visitor: pendoVisitor, account: pendoAccount });
    } else {
      pendo.initialize({
        visitor: pendoVisitor,
        account: pendoAccount,
      });
    }
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this._decodedAccessToken?.exp;
    if (expiryTime) {
      return Date.now() >= expiryTime * 1000;
    } else {
      return false; // Should we return true if there is no token at all?
    }
  }

  isRefreshTokenExpired(): boolean {
    const refreshToken = AuthService.getRefreshToken();
    if (refreshToken) {
      const decodedRefreshToken = jwt_decode<JwtTokenData>(refreshToken);
      const expiryTime: number = decodedRefreshToken?.exp;
      if (expiryTime) {
        return Date.now() >= expiryTime * 1000;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isScopeAble(scope: ApiKey.ScopesEnum, accountId?: string): boolean {
    const accountIdToCheck = accountId || AuthService.getCurrentAccountId();

    if (!accountIdToCheck || this._decodedAccessToken === null) {
      return false;
    }

    const accountUserScopes = this._decodedAccessToken.account_roles?.find(
      (ar) => ar.account_id === accountIdToCheck,
    )?.scopes;

    if (!accountUserScopes) {
      return false;
    } else {
      return accountUserScopes.includes('*') || accountUserScopes.includes(scope);
    }
  }

  isImpersonated(): boolean {
    return this._decodedAccessToken?.impersonated || false;
  }

  /**
   * If the feature-toggle is valid for this user then it will return true. Else false.
   *
   * @param feature e.g. 'cool.new.feature'
   */
  isFeatureEnabled(feature: string): boolean {
    if (feature == null || this._decodedAccessToken === null || this._decodedAccessToken.features == null) {
      return false;
    } else {
      return this._decodedAccessToken.features[feature];
    }
  }

  private reset(eraseCurrentAccountId = true): void {
    const storageKeysToRemove = [TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY];
    if (eraseCurrentAccountId) {
      storageKeysToRemove.push(CURRENT_ACCOUNT_ID_STORAGE_KEY);
    }

    for (const key of storageKeysToRemove) {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    }

    this._decodedAccessToken = null;
    this.setUser(null);

    if (eraseCurrentAccountId) {
      this.setCurrentAccountId(null);
    }
  }

  /**
   * When we pass `?token=` as a query parameter, we attempt to validate it first by:
   * 1) Decoding it
   * 2) Making an API call to `/v1/users/:id` (this might not work if scope permissions are missing for `users:read`)
   * 2b) Perhaps we need a `/v1/auth/token` or `/v1/auth/validate` endpoint...
   */
  isTokenValid(token: string): Observable<boolean> {
    try {
      const decodedToken = jwt_decode<JwtTokenData>(token);
      if (decodedToken) {
        // Override the default 'accessToken' configuration
        // See ApiModule.forRoot in app.module.ts
        this._usersService.configuration.credentials['JWT'] = token;
        const requestParams: GetUserRequestParams = {
          tilledAccount: decodedToken.account_id,
          id: decodedToken.id,
        };
        return this._usersService.getUser(requestParams).pipe(map((user) => user != null));
      }
    } catch (error) {
      return of(false);
    }
  }

  isMerchantUser(accountId?: string): boolean {
    return this.doesUserHaveAnyRolesInAccount([User.RoleEnum.MERCHANT_OWNER, User.RoleEnum.MERCHANT_ADMIN], accountId);
  }

  isAdminUser(accountId?: string): boolean {
    return this.doesUserHaveAnyRolesInAccount([User.RoleEnum.OWNER, User.RoleEnum.ADMIN], accountId);
  }

  isISVViewOnlyUser(accountId?: string): boolean {
    return this.doesUserHaveAnyRolesInAccount([User.RoleEnum.VIEW_ONLY], accountId);
  }

  private doesUserHaveAnyRolesInAccount(roles: AccountRole.RoleEnum[], accountId?: string): boolean {
    if (!this.user) {
      return false;
    }
    const accountIdToCheck = accountId || AuthService.getCurrentAccountId();
    if (!accountIdToCheck) {
      return false;
    }

    const currentUserRole = this.user.account_roles?.find((ar) => ar.account_id === accountIdToCheck)?.role;

    return roles.includes(currentUserRole);
  }
}
