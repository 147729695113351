import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InternalAccount, InternalAccountCapability, PricingTemplate } from '@tilled-api-client';
import { AuthService } from 'app/core/services/auth.service';
import {
  BillingPayoutScheduleDialogComponent,
  BillingPayoutScheduleDialogData,
  monthlyAnchorDateTypeToText,
} from './billing-payout-schedule-dialog/billing-payout-schedule-dialog.component';

@Component({
  selector: 'processing-details',
  templateUrl: './processing-details.component.html',
  styleUrls: ['./processing-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessingDetailsComponent implements OnInit {
  @Input() account: InternalAccount;
  @Input() isMerchant: boolean;

  private isUserAllowedToEdit = false;

  constructor(private _matDialog: MatDialog, private _authService: AuthService) {}

  ngOnInit(): void {
    this.isUserAllowedToEdit = this._authService.isScopeAble('accounts:write');
  }

  getVisaDiscountFee(pricingTemplate: PricingTemplate): number {
    return pricingTemplate?.card?.markups?.find((markup) => markup.card_type === 'visa')?.rate || 0;
  }

  getAmexDiscountFee(pricingTemplate: PricingTemplate): number {
    return pricingTemplate?.card?.markups?.find((markup) => markup.card_type === 'amex')?.rate || 0;
  }

  editBillingPayoutSchedules(capability: InternalAccountCapability): void {
    const data: BillingPayoutScheduleDialogData = {
      action: 'edit',
      accountId: this.account.id,
      capability: capability,
    };

    const dialogRef = this._matDialog.open(BillingPayoutScheduleDialogComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((account?: InternalAccount) => {
      if (!account) {
        return;
      }
      if (account.id === this.account.id) {
        this.account = account;
      }
    });
  }

  isCapabilityEditable(capability: InternalAccountCapability): boolean {
    if (!this.isUserAllowedToEdit) {
      return false;
    }

    const isTilledBilling = capability.processing_details?.billing_anchor != null;
    const editable = isTilledBilling && capability.status === 'active';
    return editable;
  }

  public textForMonthlyAnchorDate(anchor: Date): string {
    const anchorType = BillingPayoutScheduleDialogComponent.computeMonthlyAnchorDateType(anchor);

    if (anchorType >= 0) {
      return monthlyAnchorDateTypeToText[anchorType];
    }

    return undefined;
  }
}
