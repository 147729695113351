import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { ApplicationStep } from 'app/core/models/application-step';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { MerchantApplication } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'business-details-merchant-step',
  templateUrl: './business-details-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessDetailsStepComponent extends ComponentBase implements OnInit, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public businessDetailsForm: FormGroup;
  public steps: ApplicationStep[];
  public merchantApp: MerchantApplication;
  public codes = SelectorTypes.mccCodesArray;
  public entityTypes = SelectorTypes.businessEntityTypes;

  private subscriptions: Subscription[] = [];
  constructor(private _formBuilder: FormBuilder, private _merchantAppService: MerchantAppService) {
    super();
  }

  ngOnInit(): void {
    this.businessDetailsForm = this._formBuilder.group({
      legalName: new FormControl(this.merchantApp?.business_legal_entity?.legal_name || null),
      dba: new FormControl(this.merchantApp?.business_legal_entity?.name || null),
      type: new FormControl(this.merchantApp?.business_legal_entity?.type || null),
      businessIdentifier: new FormControl(this.merchantApp?.business_legal_entity?.tax_identification_number || null, [
        Validators.minLength(9),
      ]),
      category: new FormControl(this.merchantApp?.business_legal_entity?.category || null),
      statementDescriptor: new FormControl(this.merchantApp?.business_legal_entity?.statement_descriptor || null),
      description: new FormControl(this.merchantApp?.business_legal_entity?.description || null),
    });

    this._merchantAppService.merchantAppSteps$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((steps) => (this.steps = steps));

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessDetailsForm.disable();
          } else {
            this.businessDetailsForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onContinueClicked(accountId?: string): void {
    this.businessDetailsForm.markAllAsTouched();
    if (this.businessDetailsForm.invalid) {
      this.scrollToError();
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessDetailsForm.controls) {
      const control = this.businessDetailsForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.merchantApp.business_legal_entity.legal_name = this.businessDetailsForm.value.legalName;
    this.merchantApp.business_legal_entity.name = this.businessDetailsForm.value.dba;
    this.merchantApp.business_legal_entity.type = this.businessDetailsForm.value.type;
    this.merchantApp.business_legal_entity.tax_identification_number =
      this.businessDetailsForm.value.businessIdentifier;
    this.merchantApp.business_legal_entity.category = this.businessDetailsForm.value.category;
    this.merchantApp.business_legal_entity.statement_descriptor = this.businessDetailsForm.value.statementDescriptor;
    this.merchantApp.business_legal_entity.description = this.businessDetailsForm.value.description;

    this._merchantAppService.updateMerchantApplication(this.merchantApp, 1, accountId);
  }

  private isAppUnsaved(): boolean {
    return !(
      _compareTwoStrings(
        this.merchantApp.business_legal_entity?.legal_name,
        this.businessDetailsForm.value.legalName,
      ) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.name, this.businessDetailsForm.value.dba) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.type, this.businessDetailsForm.value.type) &&
      _compareTwoStrings(
        this.merchantApp.business_legal_entity?.tax_identification_number,
        this.businessDetailsForm.value.businessIdentifier,
      ) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.category, this.businessDetailsForm.value.category) &&
      _compareTwoStrings(
        this.merchantApp.business_legal_entity?.statement_descriptor,
        this.businessDetailsForm.value.statementDescriptor,
      ) &&
      _compareTwoStrings(
        this.merchantApp.business_legal_entity?.description,
        this.businessDetailsForm.value.description,
      )
    );
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.business_legal_entity;

    this.businessDetailsForm.controls['legalName'].setValue(ble?.legal_name);
    this.businessDetailsForm.controls['dba'].setValue(ble?.name);
    this.businessDetailsForm.controls['type'].setValue(ble?.type);
    this.businessDetailsForm.controls['businessIdentifier'].setValue(ble?.tax_identification_number);
    this.businessDetailsForm.controls['category'].setValue(ble?.category);
    this.businessDetailsForm.controls['statementDescriptor'].setValue(ble?.statement_descriptor);
    this.businessDetailsForm.controls['description'].setValue(ble?.description);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }
}
