<mat-chip-list>
  <!-- [color]='green' will apply class '.mat-green' -->
  <mat-chip
    id="tilled-chip"
    class="min-w-10 truncate text-sm"
    disabled
    [matTooltip]="tooltipText"
    matTooltipPosition="above"
    [color]="colorClass"
    >{{ displayStatus }}
  </mat-chip>
</mat-chip-list>
