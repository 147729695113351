//For a new demo, need to update values here, and also the primary and accent palettes in tailwind.config.js
export const demo = {
  partnerName: 'Fiserv',
  assetsLogoPath: 'assets/images/logos/',
  partnerFaviconAlt: 'Fiserv Logo',
  partnerFaviconLogo: 'fiserv-logo-favicon.png', //browser tab and loading logo (transparent bg)
  partnerLightLogo: 'fiserv-logo-light.svg', //light mode logo (dark text, transparent bg)
  partnerDarkLogo: 'fiserv-logo-dark.png', //dark mode logo (light text, transparent bg)
};

document.title = demo.partnerName;
document.getElementById('logo').style.display = '';
document.getElementById('logoImg').setAttribute('alt', demo.partnerFaviconAlt);
document.getElementById('logoImg').setAttribute('src', demo.assetsLogoPath + demo.partnerFaviconLogo);
