<div class="fixed top-0 bottom-0 h-full font-sans shadow">
  <div
    class="flex h-full w-96 flex-col bg-tilled-neutral-200 transition-transform duration-400 ease-drawer"
    [ngClass]="{
      '-translate-x-full shadow': opened,
      'translate-x-0': !opened
    }"
  >
    <!-- Header -->
    <div class="flex h-16 flex-0 items-center bg-tilled-neutral-200 drop-shadow" (click)="toggle()">
      <!-- Toggle -->
      <ng-container *ngIf="opened">
        <div class="flex flex-auto items-center justify-center">
          <div class="flex w-16 flex-0 items-center justify-center">
            <mat-icon
              class="icon-size-6"
              color="primary"
              [svgIcon]="'heroicons_outline:question-mark-circle'"
            ></mat-icon>
          </div>
          <button class="ml-auto mr-4" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <!-- Content -->
    <div
      class="m-auto flex h-full max-h-screen w-full flex-col items-center overflow-auto border-t bg-tilled-neutral-200 p-4"
    >
      <div class="mt-4 mb-16 h-[131px] w-[280px] justify-center p-4 text-center text-[30px] font-extrabold leading-10">
        Need Support? We have you covered.
      </div>
      <div class="bg-card mb-20 flex h-[210px] w-[280px] flex-none flex-col items-center rounded-2xl p-6 shadow">
        <div
          class="relative -top-14 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-tilled-neutral-100"
        >
          <mat-icon class="text-tilled-orange icon-size-24" svgIcon="heroicons_solid:question-mark-circle"></mat-icon>
        </div>
        <div class="relative -top-7 text-[24px] font-bold leading-[30px]">FAQs</div>
        <div class="text-[15px] font-semibold leading-[20px]">
          <div *ngIf="accountType === 'partner'" class="text-center">
            <a href="https://tilledpartners.zendesk.com/hc/en-us" target="_blank">Partner Help Center</a>
          </div>
          <div class="mt-2 text-center">
            <a href="https://paymentsonline.zendesk.com/hc/en-us" target="_blank">Merchant Help Center</a>
          </div>
        </div>
      </div>
      <!--            <div-->
      <!--                class="flex flex-col flex-none items-center mb-20 w-[280px] h-[210px] p-6 bg-card shadow rounded-2xl">-->
      <!--                <div-->
      <!--                    class="relative flex items-center justify-center rounded-full bg-[#FFA000] w-[75px] h-[75px] -top-14">-->
      <!--                    <mat-icon class="white-icon enlarge" svgIcon="heroicons_solid:chat-alt-2"></mat-icon>-->
      <!--                </div>-->
      <!--                <div class="relative text-[24px] leading-[30px] font-bold -top-7">-->
      <!--                    Chat with us-->
      <!--                </div>-->
      <!--                <div class="mt-2 text-[15px] leading-[20px] font-semibold">-->
      <!--                    <a href="">Chat with a support agent.</a>-->
      <!--                </div>-->
      <!--            </div>-->

      <div
        *ngIf="tilledSupport"
        class="bg-card mb-20 flex h-[210px] w-[280px] flex-none flex-col items-center rounded-2xl p-6 shadow"
      >
        <div class="relative -top-14 flex h-[75px] w-[75px] items-center justify-center rounded-full bg-[#EB5778]">
          <mat-icon class="white-icon enlarge" svgIcon="heroicons_solid:phone"></mat-icon>
        </div>
        <div class="relative -top-7 text-[24px] font-bold leading-[30px]">Call us</div>
        <div class="text-[15px] font-semibold leading-[20px]">
          <a href="tel:{{supportPhoneLink}}">{{supportPhone}}</a>
        </div>
        <div *ngIf="showTilledHours" class="mt-2 text-[15px] font-semibold leading-[20px]">8am-5pm MT, Monday-Friday</div>
      </div>
    </div>
  </div>
</div>
