/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodCreateParamsCard } from './paymentMethodCreateParamsCard';
import { PaymentMethodCreateParamsAchDebit } from './paymentMethodCreateParamsAchDebit';
import { PaymentMethodCreateParamsBillingDetails } from './paymentMethodCreateParamsBillingDetails';
import { PaymentMethodCreateParamsEftDebit } from './paymentMethodCreateParamsEftDebit';


export interface PaymentMethodCreateParams { 
    ach_debit?: PaymentMethodCreateParamsAchDebit;
    billing_details?: PaymentMethodCreateParamsBillingDetails;
    card?: PaymentMethodCreateParamsCard;
    eft_debit?: PaymentMethodCreateParamsEftDebit;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.
     */
    metadata?: { [key: string]: string; };
    /**
     * A customer provided name for the PaymentMethod.
     */
    nick_name?: string;
    /**
     * A token generated by Tilled.js representing the details of a payment method (e.g. card or bank details)
     */
    payment_token?: string;
    /**
     * The type of the PaymentMethod. An additional hash is included on the PaymentMethod with a name matching this value. It contains additional information specific to the PaymentMethod type.
     */
    type: PaymentMethodCreateParams.TypeEnum;
}
export namespace PaymentMethodCreateParams {
    export type TypeEnum = 'card' | 'ach_debit' | 'eft_debit';
    export const TypeEnum = {
        CARD: 'card' as TypeEnum,
        ACH_DEBIT: 'ach_debit' as TypeEnum,
        EFT_DEBIT: 'eft_debit' as TypeEnum
    };
}


