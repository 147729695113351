import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InternalListPayoutsRequestParams, InternalPayout } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { PayoutStatusPipe } from 'app/core/pipes/payout-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { PayoutsAppService } from 'app/core/services/payouts.app.service';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-payout-list',
  templateUrl: './payout-list.component.html',
  styleUrls: ['./payout-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PayoutListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() queryMerchantId: string = null;
  @Input() paidAtGte: string = null;
  @Input() paidAtLte: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() includeConnectedAccounts: boolean = false;
  @Input() merchantDetails: boolean = false;

  public payouts$: Observable<InternalPayout[]>;
  public payoutsViewModel$: Observable<PayoutViewModel[]>;
  public payoutsCount$: Observable<number>;

  public isLoading = true;
  public sortInfo = null;
  public hideColumns: number[] = [];
  public isMerchant: boolean = false;

  constructor(
    private _authService: AuthService,
    private _payoutsAppService: PayoutsAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _payoutStatusPipe: PayoutStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.payouts$ = this._payoutsAppService.payouts$;
    this.payoutsCount$ = this._payoutsAppService.payoutsCount$;

    this.payoutsViewModel$ = this.payouts$.pipe(map((payouts) => this.getViewModelsFromPayouts(payouts)));
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
      if (isMerchant && this.hideColumns.length === 0) {
        this.hideColumns = [1];
      }
    });
    this.setTableType();
    this.getPayouts(this.pageSize, this.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let firstChange = true;

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        firstChange = firstChange && changes[propName].isFirstChange();
      }
    }

    if (!firstChange) {
      this.setTableType();
      this.getPayouts(this.pageSize, this.pageIndex);
    }
  }

  rowClickedCallback = (data: PayoutViewModel): void => {
    this._router.navigate([`payouts/${data.account_id}/payouts/${data.id}`]);
  };

  getPayouts = (size: number, index: number): void => {
    const params: InternalListPayoutsRequestParams = {
      tilledAccount: this.queryMerchantId ?? this.accountId,
      include: 'transaction_count',
      includeConnectedAccounts: this.includeConnectedAccounts,
      paidAtGte: this.paidAtGte,
      paidAtLte: this.paidAtLte,
      offset: size * index,
      limit: size,
      sort: 'paid_at:desc',
    };
    this._payoutsAppService.getAllPayouts(params);
  };

  setTableType(): void {
    if (this.merchantDetails) {
      this.hideColumns = [1];
    }
  }

  getViewModelsFromPayouts(payouts: InternalPayout[]): PayoutViewModel[] {
    const viewModels: PayoutViewModel[] = [];
    if (!payouts || payouts.length === 0) {
      const temp: PayoutViewModel = new PayoutViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const payout of payouts) {
      const temp: PayoutViewModel = new PayoutViewModel();
      temp.id = payout.id;
      temp.payout_id = payout.id;
      temp.currency = payout.currency;
      temp.net_settlement = payout.amount;
      temp.transaction_count = payout.transaction_count.toString();
      temp.chipConfig = this._payoutStatusPipe.transform(payout);
      temp.paid_at = this._dateFormatPipe.transform(payout.paid_at);
      temp.account_id = payout.account_id;
      temp.merchant = payout.account_name;
      viewModels.push(temp);
    }
    return viewModels;
  }
}

export class PayoutViewModel {
  id: string;
  account_id: string;

  @Column({
    order: 0,
    name: 'Payout Date',
  })
  paid_at: string;

  @Column({
    order: 1,
    name: 'Merchant',
  })
  merchant: string;

  @Column({
    order: 2,
    name: 'Payout ID',
  })
  payout_id: string;

  // non display
  currency: InternalPayout.CurrencyEnum;

  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;

  @Column({
    order: 3,
    name: 'Status',
    isChip: true,
    styling: 'min-width:140px',
  })
  status: string;

  @Column({
    order: 4,
    name: 'Transaction Count',
  })
  transaction_count: string;

  @Column({
    order: 5,
    name: 'Net Settlement',
    isCurrency: true,
  })
  net_settlement: number;

  @Column({
    order: 6,
    isAction: true,
  })
  action: string;
}
