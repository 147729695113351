import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { InitialData } from 'app/app.types';
import { Observable, of } from 'rxjs';
import { FuseNavigationItem } from '../@fuse/components/navigation';
import { AuthService } from './core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
    return of({
      messages: null,
      navigation: {
        classy: defaultNavigation,
        compact: defaultNavigation,
        default: defaultNavigation,
        futuristic: defaultNavigation,
        horizontal: defaultNavigation,
      },
      notifications: null,
      //user: this._authService._currentUser,
    });
  }
}

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/dashboard',
  },
  {
    id: 'merchants',
    title: 'Merchant Management',
    isPartnerScope: true,
    type: 'collapsable',
    icon: 'heroicons_outline:collection',
    children: [
      {
        id: 'merchants.accounts',
        title: 'Merchants',
        type: 'basic',
        link: '/merchants',
      },
      {
        id: 'merchants.pricing-templates',
        title: 'Pricing Templates',
        type: 'basic',
        link: '/pricing-templates',
      },
    ],
  },
  /*{
    id: 'outbound-transfers',
    title: 'Outbound Transfers',
    type: 'basic',
    icon: 'heroicons_outline:arrow-right',
    link: '/outbound-transfers',

    children: [
      {
        id: 'outbound-transfers.outbound-transfer-details',
        title: 'Outbound Transfer Details',
        type: 'basic',
        link: '/:account_id/outbound-transfers/:id',
      },
    ],
  },*/
  {
    id: 'payments',
    title: 'Payments',
    type: 'basic',
    icon: 'heroicons_outline:credit-card',
    link: '/payments',

    children: [
      {
        id: 'payments.payment-details',
        title: 'Payment Details',
        type: 'basic',
        link: '/:account_id/payments/:id',
      },
    ],
  },
  {
    id: 'payouts',
    title: 'Payouts',
    type: 'basic',
    icon: 'heroicons_outline:library',
    link: '/payouts',
  },
  {
    id: 'disputes',
    title: 'Disputes',
    type: 'basic',
    icon: 'heroicons_outline:exclamation',
    link: '/disputes',
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: 'heroicons_outline:folder',
    type: 'basic',
    link: '/reports',
  },
  {
    id: 'customers',
    title: 'Customers',
    type: 'basic',
    isMerchantScope: true,
    isPartnerScope: false,
    icon: 'heroicons_outline:users',
    link: '/customers',
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings',
  },
  {
    id: 'developers',
    isPartnerScope: true,
    title: 'Developers',
    type: 'basic',
    link: '/developers',
    icon: 'heroicons_outline:code',
  },
];
// TODO: If we wanted to offer different navigation, we would need to spend some time building these out.
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
