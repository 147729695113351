import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { FuseAlertService } from '@fuse/components/alert';
import { ComponentBase } from 'app/core/componentBase';
import {
  ACH_DEBIT_TERMS_LINK_NAME,
  CARD_TERMS_LINK_NAME,
  PORTAL_TERMS_LINK_NAME,
  PRIVACY_POLICY_LINK_NAME,
} from 'app/core/constants';
import { ApplicationStep } from 'app/core/models/application-step';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import {
  CardPricingTemplateViewModel,
  DebitPricingTemplateViewModel,
} from 'app/shared/connected-account/connected-account-dialog/connected-account-dialog.component';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
  MerchantApplication,
  MerchantApplicationBusinessLegalEntity,
  PricingTemplate,
} from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'submit-application-step',
  templateUrl: './submit-application-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitApplicationStepComponent extends ComponentBase implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() merchantAccountId$: Observable<string> = null;

  public submitApplicationForm: FormGroup;
  public steps: ApplicationStep[];
  public merchantApp: MerchantApplication;
  public cardPricingTemplate: CardPricingTemplateViewModel;
  public debitPricingTemplate: DebitPricingTemplateViewModel;
  public hasBoth: boolean;
  private _cardPricingTemplate$ = new BehaviorSubject<CardPricingTemplateViewModel>(null);
  public cardPricingTemplate$ = this._cardPricingTemplate$.asObservable();
  private _debitPricingTemplate$ = new BehaviorSubject<DebitPricingTemplateViewModel>(null);
  public debitPricingTemplate$ = this._debitPricingTemplate$.asObservable();
  public disableButton: boolean;

  public businessDetailsComplete: boolean;
  public businessContactComplete: boolean;
  public processingVolumeComplete: boolean;
  public businessRepresentativesComplete: boolean;
  public bankAccountComplete: boolean;
  public pricingAndTermsComplete: boolean;
  public canSubmitApplication: boolean;
  public termsAgreed: boolean;

  public merchantTermsLink: string;
  public portalTermsLink: string;
  public privacyPolicyLink: string;
  public achDebitTermsLink: string;

  private submittedApplicationErrors$: Observable<any>;
  private submittedApplicationResponse$: Observable<any>;
  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  public alertMessage: string;
  private accountId: string;
  private _submittingApp$ = new Subject<boolean>();
  public submittingApp$ = this._submittingApp$.asObservable();

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _authService: AuthService,
    private _router: Router,
    private _fuseAlertService: FuseAlertService,
    private _alertService: AlertService,
    private _accountAppService: AccountAppService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this._merchantAppService.merchantAppSteps$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((steps) => (this.steps = steps));
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.handleUpdatedApplication();
      });

    if (this.disabled$) {
      this.disabled$.subscribe((isDisabled) => {
        if (isDisabled) {
          this.submitApplicationForm.disable();
          this.disableButton = true;
        } else {
          this.submitApplicationForm.enable();
          this.disableButton = false;
        }
      });
    }

    this.handleUpdatedApplication();

    this.submittedApplicationResponse$ = this._merchantAppService.submittedApplicationResponse$;
    this.submittedApplicationResponse$.subscribe({
      next: (res) => {
        this._submittingApp$.next(false);
        if (this.forConsole) {
          const message: TilledAlert = {
            message: `Application for ${this.merchantApp.business_legal_entity?.legal_name} was submitted successfully`,
            title: 'Application submitted',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);
          this.goToStep(0);
          this._accountAppService.getConnectedAccountById(this.accountId);
        } else {
          this._router.navigate(['/onboarding/submitted']);
        }
      },
    });

    this.submittedApplicationErrors$ = this._merchantAppService.submittedApplicationErrors$;
    this.submittedApplicationErrors$.subscribe({
      next: (errResponse) => {
        this._submittingApp$.next(false);
        if (errResponse?.error?.message) {
          const text = errResponse.error.message.split('\n');
          this.alertMessage = '\u2022' + text.splice(0, text.length - 1).join('\n\u2022');
          this._displayAlert$.next(true);
          this._fuseAlertService.show('merchantAppAlertBox');
        }
      },
    });

    if (this.merchantAccountId$) {
      this.merchantAccountId$.subscribe({
        next: (accountId) => {
          this.accountId = accountId;
        },
      });
    }
  }

  onAgreeTermsClicked(checkbox: MatCheckboxChange): void {
    this.termsAgreed = checkbox.checked;
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(4);
  }

  onContinueClicked(event: string): void {
    if (this.submitApplicationForm.invalid || !this.canSubmitApplication) {
      return;
    }

    this.merchantApp.accept_terms_and_conditions = this.submitApplicationForm.value.acceptedTerms;

    this._submittingApp$.next(true);

    this._merchantAppService.updateAndSubmitMerchantApplication(this.merchantApp, this.accountId);
  }

  goToStep(stepNumber: number): void {
    this._merchantAppService.updateCurrentStep(stepNumber);
  }

  getTermsLinks(): void {
    this.merchantTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === CARD_TERMS_LINK_NAME,
    )?.link;
    this.portalTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PORTAL_TERMS_LINK_NAME,
    )?.link;
    this.privacyPolicyLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PRIVACY_POLICY_LINK_NAME,
    )?.link;
    this.achDebitTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === ACH_DEBIT_TERMS_LINK_NAME,
    )?.link;
  }

  private handleUpdatedApplication(): void {
    this.getTermsLinks();

    this.submitApplicationForm = this._formBuilder.group({
      acceptedTerms: new FormControl(this.merchantApp?.accept_terms_and_conditions || false),
    });
    this.termsAgreed = this.merchantApp?.accept_terms_and_conditions;

    this.businessDetailsComplete = this._merchantAppService.isBusinessDetailsStepComplete(this.merchantApp);
    this.businessContactComplete = this._merchantAppService.isBusinessContactInformationComplete(this.merchantApp);
    this.processingVolumeComplete = this._merchantAppService.isPaymentProcessingVolumeComplete(this.merchantApp);
    this.businessRepresentativesComplete = this._merchantAppService.isBusinessRepresentativesComplete(this.merchantApp);
    this.bankAccountComplete = this._merchantAppService.isBankingInformationComplete(this.merchantApp);
    this.pricingAndTermsComplete = this._merchantAppService.isReviewPricingAndTermsComplete(this.merchantApp);

    this.canSubmitApplication =
      this.businessDetailsComplete &&
      this.businessContactComplete &&
      this.processingVolumeComplete &&
      this.businessRepresentativesComplete &&
      this.bankAccountComplete;

    const cardPricing = this.merchantApp.pricing_templates.find(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
    );
    if (cardPricing) {
      this.cardPricingTemplate = new CardPricingTemplateViewModel(cardPricing);
    }
    if (this.cardPricingTemplate) {
      this._cardPricingTemplate$.next(this.cardPricingTemplate);
    }

    const debitPricing = this.merchantApp.pricing_templates.find(
      (p) =>
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
    );
    if (debitPricing) {
      this.debitPricingTemplate = new DebitPricingTemplateViewModel(debitPricing);
    }
    if (this.debitPricingTemplate) {
      this._debitPricingTemplate$.next(this.debitPricingTemplate);
    }

    if (this.cardPricingTemplate && this.debitPricingTemplate) {
      this.hasBoth = true;
    } else {
      this.hasBoth = false;
    }

    if (this.merchantApp.business_legal_entity) {
      const validPricingTemplate = this.cardPricingTemplate || this.debitPricingTemplate;
      if (validPricingTemplate.currency === PricingTemplate.CurrencyEnum.USD) {
        this.merchantApp.business_legal_entity.currency = MerchantApplicationBusinessLegalEntity.CurrencyEnum.USD;
        this.merchantApp.business_legal_entity.locale = MerchantApplicationBusinessLegalEntity.LocaleEnum.EN_US;
        this.merchantApp.business_legal_entity.region = MerchantApplicationBusinessLegalEntity.RegionEnum.US;
      } else if (validPricingTemplate.currency === PricingTemplate.CurrencyEnum.CAD) {
        this.merchantApp.business_legal_entity.currency = MerchantApplicationBusinessLegalEntity.CurrencyEnum.CAD;
        this.merchantApp.business_legal_entity.locale = MerchantApplicationBusinessLegalEntity.LocaleEnum.EN_CA;
        this.merchantApp.business_legal_entity.region = MerchantApplicationBusinessLegalEntity.RegionEnum.CA;
      }
    }

    this._changeDetectorRef.markForCheck();
  }
}
