<div class="-mb-8 max-h-[100vh] w-fit overflow-y-auto sm:min-w-120">
  <mat-stepper #stepper cdk-scrollable labelPosition="bottom">
    <mat-step label="New Outbound Transfer" color="primary">
      <form-card
        (exitClick)="closeDialog()"
        (nextClick)="nextPage()"
        [backButton]="false"
        [nextButton]="true"
        [nextDisabled]="newOutboundTransferForm.invalid"
      >
        <form [formGroup]="newOutboundTransferForm">
          <div data-test-id="modal-header" class="grid w-full justify-items-center pb-2 text-center">
            <!-- Title -->
            <tilled-heading-h2 class="pb-2">Create New Outbound Transfer</tilled-heading-h2>
            <tilled-paragraph-p3 class="max-w-120 pb-4">{{
              enablePaymentMethod
                ? 'Enter details about the transfer you wish to make to the customer’s bank account.'
                : 'Set the amount you wish to transfer to the customer’s bank account.'
            }}</tilled-paragraph-p3>
          </div>
          <div>
            <div [ngClass]="!enablePaymentMethod ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="standard" class="-mt-4 w-full">
                <tilled-label-l1 secondary="true">Payment Method</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="paymentMethod"
                  [(value)]="selectedPaymentMethod"
                  [disabled]="!enablePaymentMethod"
                  placeholder="Select a payment method"
                  data-test-id="payment-method"
                >
                  <mat-option
                    *ngFor="let paymentMethod of availablePaymentMethods | keyvalue"
                    [value]="paymentMethod.key"
                  >
                    {{ paymentMethod.value }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="text-sm"
                  color="warn"
                  *ngIf="newOutboundTransferForm.get('paymentMethod').hasError('required')"
                  >A payment method is required</mat-error
                >
              </mat-form-field>
            </div>

            <div [ngClass]="!enableCurrency ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="standard" class="-mt-4 w-full pb-4">
                <tilled-label-l1 secondary="true" class="">Currency</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="currency"
                  [(value)]="selectedCurrency"
                  [disabled]="!enableCurrency"
                  placeholder="Select a currency"
                  data-test-id="currency"
                >
                  <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key">
                    {{ currency.value }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="text-sm"
                  color="warn"
                  *ngIf="newOutboundTransferForm.get('currency').hasError('required')"
                  >A currency is required</mat-error
                >
              </mat-form-field>
            </div>

            <outline-input
              name="amount"
              showCurrency="'true'"
              [errors]="
                newOutboundTransferForm.get('amount').hasError('required') &&
                newOutboundTransferForm.get('amount').touched
              "
              label="Transfer Amount"
              inputTextStyle="mb-2 text-tilled-primary py-1"
              mask="separator.2"
              data-test-id="transfer-amount"
            >
              <div class="pt-1 text-sm text-warn" *ngIf="newOutboundTransferForm.get('amount').invalid">
                A valid transfer amount is required
              </div>
            </outline-input>
          </div>
        </form>
      </form-card>
    </mat-step>

    <mat-step label="Payment Method Details">
      <!-- ACH Debit -->
      <div
        [ngClass]="
          selectedPaymentType == 'ach_debit' || selectedPaymentType == 'eft_debit'
            ? 'visible grid min-w-100 sm:min-w-[700px]'
            : 'hidden'
        "
      >
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="sentOutboundTransfer()"
          [submitDisabled]="bankTransferForm.invalid || tilledBankForm?.invalid || (sendingTransfer$ | async)"
          [submitText]="'Send Outbound Transfer'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
        >
          <form [formGroup]="bankTransferForm">
            <div data-test-id="ach-header" class="grid min-w-fit justify-items-center px-4 pb-2">
              <!-- Title -->
              <div class="flex-cols flex pb-2">
                <tilled-heading-h2>{{ pageTitle }}</tilled-heading-h2>
                <tilled-heading-h2 primaryHighlight="true"
                  >&nbsp;{{
                    newOutboundTransferForm.get('amount').value * 100 | minorUnitsToCurrency
                  }}</tilled-heading-h2
                >
              </div>
              <tilled-paragraph-p3
                >Enter the customer’s bank account information where funds should be deposited.</tilled-paragraph-p3
              >
            </div>
            <div class="flex flex-col">
              <div class="grid w-[full] grid-cols-1 gap-x-6 sm:grid-cols-4">
                <div class="col-span-2 flex flex-col pt-8">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">Account Number</tilled-label-l1>
                    <div class="inputField" id="bank-account-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showAccountNumberError; else noAchAccountNumberError"
                      @fadeIn
                    >
                      A valid account number is required
                    </div>
                    <ng-template #noAchAccountNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="
                    selectedPaymentType == 'ach_debit'
                      ? 'col-span-1 flex w-[170px] flex-col pt-8'
                      : 'col-span-2 flex flex-col pt-8'
                  "
                >
                  <div class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else eftLabel">
                      <tilled-label-l1 secondary="true" class="pb-1.5">Routing Number</tilled-label-l1>
                    </ng-container>
                    <ng-template #eftLabel>
                      <tilled-label-l1 secondary="true" class="pb-1.5"
                        >Transit Number and Institution Id</tilled-label-l1
                      >
                    </ng-template>
                    <div class="inputField" id="bank-routing-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showRoutingNumberError; else noRoutingNumberError"
                      @fadeIn
                    >
                      A valid
                      {{ selectedPaymentType == 'ach_debit' ? 'routing number' : 'transit number and institution ID' }}
                      is required
                    </div>
                    <ng-template #noRoutingNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedPaymentType == 'ach_debit'" class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Account Type</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-account-type"
                      class="text-secondary pt-4 text-p3"
                      formControlName="accountType"
                    >
                      <mat-option [value]="'checking'">Checking</mat-option>
                      <mat-option [value]="'savings'">Savings</mat-option>
                    </mat-select>
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankTransferForm.get('accountType').hasError('required')"
                      >An account type is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <!-- new row -->
                <div class="col-span-4 -mt-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Account Holder Name</tilled-label-l1>
                    <input
                      data-test-id="ach-account-holder"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="accountholderName"
                      placeholder=""
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankTransferForm.get('accountholderName').hasError('required')"
                      >An account holder name is required</mat-error
                    >
                  </mat-form-field>
                </div>

                <!-- billing details -->
                <div class="col-span-2 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Address line 1</tilled-label-l1>
                    <input
                      data-test-id="ach-address-1"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="street1"
                      placeholder=""
                    />
                    <mat-error class="text-sm" color="warn" *ngIf="bankTransferForm.get('street1').hasError('required')"
                      >An address line 1 is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-2 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Address line 2</tilled-label-l1>
                    <input
                      data-test-id="ach-address-2"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="street2"
                      placeholder=""
                    />
                  </mat-form-field>
                </div>
                <!-- new row -->
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-country"
                      class="text-secondary pt-4 text-p3"
                      formControlName="country"
                      placeholder=""
                    >
                      <mat-option
                        *ngFor="let countryAndCode of countryCodeMap | keyvalue : originalOrder"
                        [value]="countryAndCode.value"
                      >
                        {{ countryAndCode.key }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="text-sm" color="warn" *ngIf="bankTransferForm.get('country').hasError('required')"
                      >A country is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">State</tilled-label-l1>
                    <mat-select
                      data-test-id="ach-state"
                      class="text-secondary pt-4 text-p3"
                      formControlName="state"
                      placeholder=""
                    >
                      <mat-option *ngFor="let stateAndCode of stateCodeMap | keyvalue" [value]="stateAndCode.key">
                        {{ stateAndCode.value }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="text-sm" color="warn" *ngIf="bankTransferForm.get('state').hasError('required')"
                      >A state is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true">City</tilled-label-l1>
                    <input
                      data-test-id="ach-city"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="city"
                      placeholder=""
                    />
                    <mat-error class="text-sm" color="warn" *ngIf="bankTransferForm.get('city').hasError('required')"
                      >A city is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-span-1 -mb-2 flex flex-col">
                  <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else postalLable">
                      <tilled-label-l1 secondary="true">Zip</tilled-label-l1>
                    </ng-container>
                    <ng-template #postalLable>
                      <tilled-label-l1 secondary="true">Postal Code</tilled-label-l1>
                    </ng-template>
                    <input
                      data-test-id="ach-zip"
                      class="pt-4 text-p3 text-tilled-primary"
                      matInput
                      formControlName="postalCode"
                      placeholder="12345"
                      maxlength="10"
                    />
                    <mat-error
                      class="text-sm"
                      color="warn"
                      *ngIf="bankTransferForm.get('postalCode').hasError('required')"
                      >A {{ selectedPaymentType == 'ach_debit' ? 'zip' : 'postal code' }} is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <!-- end of ACH Info 4 col grid-->
            </div>
          </form>
        </form-card>
      </div>
    </mat-step>
  </mat-stepper>
</div>
