/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BalanceTransactionSourceTypeSummary { 
    currency: BalanceTransactionSourceTypeSummary.CurrencyEnum;
    fee_amount: number;
    fee_count: number;
    net: number;
    revenue_amount: number;
    revenue_count: number;
    revenue_failure_amount: number;
    revenue_failure_count: number;
    source_type: BalanceTransactionSourceTypeSummary.SourceTypeEnum;
}
export namespace BalanceTransactionSourceTypeSummary {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type SourceTypeEnum = 'charge' | 'refund' | 'payout' | 'payment_method' | 'platform_fee' | 'platform_fee_refund' | 'dispute' | 'account' | 'outbound_transfer';
    export const SourceTypeEnum = {
        CHARGE: 'charge' as SourceTypeEnum,
        REFUND: 'refund' as SourceTypeEnum,
        PAYOUT: 'payout' as SourceTypeEnum,
        PAYMENT_METHOD: 'payment_method' as SourceTypeEnum,
        PLATFORM_FEE: 'platform_fee' as SourceTypeEnum,
        PLATFORM_FEE_REFUND: 'platform_fee_refund' as SourceTypeEnum,
        DISPUTE: 'dispute' as SourceTypeEnum,
        ACCOUNT: 'account' as SourceTypeEnum,
        OUTBOUND_TRANSFER: 'outbound_transfer' as SourceTypeEnum
    };
}


