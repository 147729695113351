import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { HOMEPAGE_ROUTE } from 'app/core/constants';
import { Observable, map } from 'rxjs';
import { AccountCapability } from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingRouteGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user && !this.authService.isMerchantUser()) {
      this.router.navigate([HOMEPAGE_ROUTE]);
      return false;
    }

    return this.authService.account$.pipe(
      map((account) => {
        if (account.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
          this.router.navigate([HOMEPAGE_ROUTE]);
          return false;
        }
        return true;
      }),
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If it is a partner, then redirect to dashbaord. Only merchant users should be using this route
    if (this.authService.user && !this.authService.isMerchantUser()) {
      this.router.navigate([HOMEPAGE_ROUTE]);
      return false;
    }

    return this.authService.account$.pipe(
      map((account) => {
        if (account.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
          this.router.navigate([HOMEPAGE_ROUTE]);
          return false;
        }
        return true;
      }),
    );
  }
}
