import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';

@Component({
  selector: 'merchant-app-alert',
  templateUrl: './merchant-app-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MerchantAppAlertComponent extends ComponentBase implements OnInit {
  @Output() editClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() alertName: string;
  @Input() title: string;
  @Input() description: string;

  constructor() {
    super();
  }

  ngOnInit() {}

  onButtonClick(text) {
    this.editClick.emit();
  }
}
