import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { BrandingService } from 'app/core/services/branding.service';
import { TilledTextGeneric } from '../tilled-text-generic.component';

@Component({
  selector: 'tilled-button-generic-text',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledButtonTextGeneric implements TilledTextGeneric, OnChanges {
  @Input() styles: string = '';
  @Input() white: boolean = false;
  @Input() secondary: boolean = false;
  @Input() breakWords: boolean = false;
  @Input() forceWhite: boolean = false;

  public internalStyles = '';
  private isWhiteLabel: boolean = false;
  private whiteText: boolean = true;

  constructor(private _brandingService: BrandingService) {
    this._brandingService.isWhileLabel$.subscribe((res) => {
      this.isWhiteLabel = res;
    });
    this._brandingService.secondaryColor$.subscribe((color) => {
      if (color) {
        this.whiteText = this._brandingService.determineWhiteTextColorFromBgColor(color);
        this.checkStyles();
      }
    });
  }

  ngOnInit() {
    this.checkStyles();
  }

  ngOnChanges() {
    this.checkStyles();
  }

  checkStyles() {
    this.styles = this.internalStyles;
    this.styles += ' font-semibold font-openSans';
    if (this.breakWords) {
      this.styles += ' break-words ';
    }
    if (this.forceWhite) {
      this.styles += ' text-white';
    } else if (this.isWhiteLabel && !this.whiteText) {
      this.styles += ' text-black';
    } else if (this.white) {
      this.styles += ' text-tilled-neutral-100';
    } else if (this.secondary) {
      this.styles += ' text-tilled-secondary';
    } else {
      this.styles += ' text-tilled-primary';
    }
  }
}
