<div class="min-w-50">
  <div class="-mr-4 flex justify-end pt-2">
    <mat-icon style="cursor: pointer" (click)="onButtonClick('exit')" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-content></ng-content>
  <div class="flex justify-center">
    <div class="flex-1"></div>
    <div *ngIf="displayAlert" class="flex max-w-100 justify-center pb-4 pt-2">
      <fuse-alert
        [type]="'warn'"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="false"
        [name]="'formCardAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>
    <div class="flex-1">
      <div *ngIf="!centerButton; else center" class="flex justify-end pt-5 pb-4">
        <div *ngIf="backButton">
          <tilled-button
            (buttonClick)="onButtonClick('back')"
            buttonText="Back"
            rounded="true"
            [whiteText]="false"
            textColor="tilled-secondary"
            bgColor="white"
          ></tilled-button>
        </div>
        <div *ngIf="nextButton">
          <tilled-button
            (buttonClick)="onButtonClick('next')"
            buttonText="Next"
            rounded="true"
            [disabled]="nextDisabled"
            textColor="white"
            [whiteText]="true"
            bg-color="tilled-accent"
          ></tilled-button>
        </div>
        <div *ngIf="submitButton">
          <tilled-button
            (buttonClick)="onButtonClick('submit')"
            [disabled]="submitDisabled"
            [buttonText]="submitText"
            rounded="true"
          ></tilled-button>
        </div>
      </div>
      <ng-template #center class="flex justify-center pb-4">
        <tilled-button
          [buttonText]="centerButton"
          (buttonClick)="onButtonClick('center')"
          rounded="true"
        ></tilled-button>
      </ng-template>
    </div>
  </div>
</div>
