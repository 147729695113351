import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, Subject, tap } from 'rxjs';
import {
  GetMerchantApplicationRequestParams,
  InternalGetMerchantApplicationRequestParams,
  InternalService,
  MerchantApplication,
  MerchantApplicationBusinessLegalEntity,
  MerchantApplicationCreateParams,
  OnboardingService,
  UpdateMerchantApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src';
import {
  GetPlaidLinkTokenRequestParams,
  PlaidService,
  PlaidUpdateMerchantApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src/api/plaid.service';
import { ApplicationStep } from '../../core/models/application-step';
import { AuthService } from '../../core/services/auth.service';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { PlaidConfig, PlaidEventsConfig, PlaidLinkAppService } from './plaid-link.app.service';
import RegionEnum = MerchantApplicationBusinessLegalEntity.RegionEnum;

@Injectable({
  providedIn: 'root',
})
export class MerchantAppService {
  private stepsSubject = new BehaviorSubject<ApplicationStep[]>(null);
  private currentStepSubject = new BehaviorSubject<number>(0);
  private applicationResponse = new BehaviorSubject<MerchantApplication>(null);
  private submittedApplicationResponse = new Subject();
  public merchantAppSteps$: Observable<ApplicationStep[]> = this.stepsSubject.asObservable();
  public merchantApplicationResponse$: Observable<MerchantApplication> = this.applicationResponse.asObservable();
  //.pipe(skipWhile((value, index) => !value));
  public currentStep$: Observable<number> = this.currentStepSubject.asObservable();
  public submittedApplicationResponse$: Observable<any> = this.submittedApplicationResponse.asObservable();

  private submittedApplicationErrors = new BehaviorSubject<any>(null);
  public submittedApplicationErrors$: Observable<any> = this.submittedApplicationErrors.asObservable();

  private _applicationUpdatedAt$ = new BehaviorSubject<string>(null);
  public applicationUpdatedAt$: Observable<any> = this._applicationUpdatedAt$.asObservable();
  private _applicationSuccessfullyUpdatedByPlaid = new Subject<boolean>();
  public applicationSuccessfullyUpdatedByPlaid$: Observable<boolean> =
    this._applicationSuccessfullyUpdatedByPlaid.asObservable();

  constructor(
    private _merchantApplicationService: OnboardingService,
    private _authService: AuthService,
    private _internalService: InternalService,
    private _plaidService: PlaidService,
    private _alertService: AlertService,
    private _plaidLinkAppService: PlaidLinkAppService,
  ) {}

  public updateCurrentStep(step: number) {
    this.currentStepSubject.next(step);
  }

  public getLinkToken(eventListeners: PlaidEventsConfig, redirectUri: string) {
    const params: GetPlaidLinkTokenRequestParams = {
      tilledAccount: AuthService.getCurrentAccountId(),
      redirect: redirectUri,
    };
    this._plaidService.getPlaidLinkToken(params).subscribe({
      next: (response) => {
        const linkScript: any = document.createElement('script');
        linkScript.type = 'text/javascript';
        linkScript.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        linkScript.onerror = (e: any) => console.log(e);

        const plaidConfig: PlaidConfig = {
          token: response.token,
          ...eventListeners,
        };
        this._plaidLinkAppService.createPlaid(plaidConfig).then((linkHandler) => {
          linkHandler?.open();
        });
        return;
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not load Plaid interface.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public async updatePlaidAccessToken(token: string) {
    const params: PlaidUpdateMerchantApplicationRequestParams = {
      token: token,
      tilledAccount: AuthService.getCurrentAccountId(),
    };
    return this._plaidService.plaidUpdateMerchantApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this._applicationSuccessfullyUpdatedByPlaid.next(true);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public loadApplication(): Observable<MerchantApplication> {
    const params: GetMerchantApplicationRequestParams = {
      accountId: AuthService.getCurrentAccountId(),
    };

    return this._merchantApplicationService.getMerchantApplication(params).pipe(
      tap((application) => {
        if (!application.business_legal_entity) {
          application.business_legal_entity = {} as MerchantApplicationBusinessLegalEntity;
        }
        this.loadSteps(application);
        this.applicationResponse.next(application);
      }),
      catchError((err) => {
        if (err.status === 403) {
          return of(null);
        }
        // TODO: get alerts working for retrieving merchant app
        throw JSON.stringify(err);
      }),
    );
  }

  public internalGetApplicationById(accountId: string): Observable<MerchantApplication> {
    const params: InternalGetMerchantApplicationRequestParams = {
      tilledAccount: accountId,
    };

    return this._internalService.internalGetMerchantApplication(params).pipe(
      tap((application) => {
        if (!application.business_legal_entity) {
          application.business_legal_entity = {} as MerchantApplicationBusinessLegalEntity;
        }
        this.loadSteps(application);
        this.applicationResponse.next(application);
        this._applicationUpdatedAt$.next(application.updated_at);
      }),
      catchError((err) => {
        throw JSON.stringify(err);
      }),
    );
  }

  public updateMerchantApplication(updatedApp: MerchantApplication, nextStep: number, accountId?: string) {
    const params: UpdateMerchantApplicationRequestParams = {
      accountId: accountId ?? AuthService.getCurrentAccountId(),
      merchantApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this._merchantApplicationService
      .updateMerchantApplication(params)
      .pipe(
        tap((application) => {
          this.loadSteps(application);
          this.currentStepSubject.next(nextStep);
          this._applicationUpdatedAt$.next(application.updated_at);
        }),
        catchError((err) => {
          if (err.error) {
            if (err.error.statusCode === 400) {
              const message: TilledAlert = {
                message: err.error.message,
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            } else {
              // generic catch all for error responses
              const message: TilledAlert = {
                message: 'Could not update application, please try again or contact support for assistance',
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            }
          }
          throw 'Error loading application: ' + JSON.stringify(err);
        }),
      )
      .subscribe((response) => this.applicationResponse.next(response));
  }

  public updateAndSubmitMerchantApplication(updatedApp: MerchantApplication, accountId?: string) {
    const params: UpdateMerchantApplicationRequestParams = {
      accountId: accountId ?? AuthService.getCurrentAccountId(),
      merchantApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this._merchantApplicationService.updateMerchantApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this.submitMerchantApplication(accountId);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application, please try again or contact support for assistance',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  private submitMerchantApplication(accountId?: string): void {
    this._merchantApplicationService
      .submitMerchantApplication({ accountId: accountId ?? AuthService.getCurrentAccountId() })
      .subscribe({
        next: (response) => {
          this.submittedApplicationResponse.next(response);
        },
        error: (err) => {
          this.submittedApplicationErrors.next(err);
        },
      });
  }

  public loadSteps(application: MerchantApplication) {
    const steps = new Array<ApplicationStep>();
    steps.push(
      {
        order: 0,
        title: 'Business Details',
        icon: 'format_align_left',
        complete: this.isBusinessDetailsStepComplete(application),
      },
      {
        order: 1,
        title: 'Contact Info',
        icon: 'phone',
        complete: this.isBusinessContactInformationComplete(application),
      },
      {
        order: 2,
        title: 'Processing Volumes',
        icon: 'credit_card',
        complete: this.isPaymentProcessingVolumeComplete(application),
      },
      {
        order: 3,
        title: 'Representatives',
        icon: 'badge',
        complete: this.isBusinessRepresentativesComplete(application),
      },
      {
        order: 4,
        title: 'Bank Account',
        icon: 'account_balance',
        complete: this.isBankingInformationComplete(application),
      },
      {
        order: 5,
        title: 'Submit Application',
        icon: 'check',
        complete: this.isReviewPricingAndTermsComplete(application),
      },
    );
    this.stepsSubject.next(steps);
  }

  public isBusinessDetailsStepComplete(application: MerchantApplication): boolean {
    return !!(
      application.business_legal_entity.legal_name &&
      application.business_legal_entity.name &&
      application.business_legal_entity.type &&
      application.business_legal_entity.tax_identification_number &&
      application.business_legal_entity.category &&
      application.business_legal_entity.statement_descriptor &&
      application.business_legal_entity.description
    );
  }

  public isBusinessContactInformationComplete(application: MerchantApplication): boolean {
    return !!(
      application.business_legal_entity.phone &&
      application.business_legal_entity.company_email &&
      application.business_legal_entity.address.street &&
      application.business_legal_entity.address.city &&
      application.business_legal_entity.address.state &&
      application.business_legal_entity.address.zip &&
      application.business_legal_entity.address.country
    );
  }

  public isPaymentProcessingVolumeComplete(application: MerchantApplication): boolean {
    return !!(
      application.business_legal_entity?.average_transaction_amount &&
      application.business_legal_entity?.yearly_volume_range &&
      application.business_legal_entity?.average_transactions_per_month
    );
  }

  public isBusinessRepresentativesComplete(application: MerchantApplication): boolean {
    if (!application.business_legal_entity?.principals) {
      return false;
    }

    for (const principal of application.business_legal_entity?.principals) {
      if (
        !(
          (application.product_codes[0].region === RegionEnum.CA ||
            application.business_legal_entity?.type === MerchantApplicationBusinessLegalEntity.TypeEnum.CHARITY ||
            application.business_legal_entity?.type === MerchantApplicationBusinessLegalEntity.TypeEnum.GOV ||
            application.business_legal_entity?.type === MerchantApplicationBusinessLegalEntity.TypeEnum.NPCORP ||
            principal.ssn) &&
          principal.first_name &&
          principal.last_name &&
          principal.phone &&
          principal.date_of_birth &&
          principal.job_title &&
          principal.percentage_shareholding != null &&
          principal.address?.street &&
          principal.address?.city &&
          principal.address?.state &&
          principal.address?.zip &&
          principal.address?.country
        )
      ) {
        return false;
      }
      if (
        application.product_codes[0].region === RegionEnum.CA &&
        principal.address.years_at_address <= 3 &&
        !(
          principal.address?.street &&
          principal.address?.city &&
          principal.address?.state &&
          principal.address?.zip &&
          principal.address?.country
        )
      ) {
        return false;
      }
    }

    const applicants = application.business_legal_entity.principals.filter(
      (principal) => principal.is_applicant === true,
    );
    if (applicants.length !== 1) {
      return false;
    }
    const controlProngs = application.business_legal_entity.principals.filter(
      (principal) => principal.is_control_prong === true,
    );
    if (controlProngs.length !== 1) {
      return false;
    }
    return true;
  }

  public isBankingInformationComplete(application: MerchantApplication): boolean {
    return !!(
      application.business_legal_entity?.bank_account?.account_number &&
      application.business_legal_entity?.bank_account?.routing_number &&
      application.business_legal_entity?.bank_account?.type &&
      application.business_legal_entity?.bank_account?.account_holder_name &&
      application.business_legal_entity?.bank_account?.bank_name
    );
  }

  public isReviewPricingAndTermsComplete(application: MerchantApplication): boolean {
    return application.accept_terms_and_conditions;
  }

  private mapResponseToParams(response: MerchantApplication): MerchantApplicationCreateParams {
    const params: MerchantApplicationCreateParams = {
      accept_terms_and_conditions: response.accept_terms_and_conditions,
      business_legal_entity: response.business_legal_entity,
    };

    return params;
  }
}
