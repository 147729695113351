import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IndexedDBService } from './core/db/indexed-db/indexed-db.service';
import { AuthService } from './core/services/auth.service';
import { BrandingService } from './core/services/branding.service';

const demo = require('../assets/js/asset-load.js').demo;

const TOKEN_SHARING_CHANNEL = 'token-sharing';
const REQUESTING_TOKEN = 'requesting-token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public bc = new BroadcastChannel(TOKEN_SHARING_CHANNEL);
  constructor(
    private _brandingService: BrandingService,
    private _authService: AuthService,
    private _indexedDbService: IndexedDBService,
    private _titleService: Title,
  ) {
    this.addBroadcastChannelListener();
    this.bc.postMessage(REQUESTING_TOKEN);
  }

  private addBroadcastChannelListener() {
    this.bc.addEventListener('message', (event) => {
      if (event.data === REQUESTING_TOKEN) {
        new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
          accessToken: AuthService.getAccessToken(),
          refreshToken: AuthService.getRefreshToken(),
        });
      } else {
        const { accessToken, refreshToken } = event.data;
        accessToken && this._authService.setAccessToken(accessToken, false);
        refreshToken && this._authService.setRefreshToken(refreshToken, false);
      }
    });
  }

  ngOnInit(): void {
    // this.setFuseConfig();
    this._titleService.setTitle(demo.partnerName);
  }

  isImpersonated(): boolean {
    return this._authService.isImpersonated();
  }
}
