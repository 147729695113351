<merchant-app-card
  [stepNumber]="!forConsole ? '3' : null"
  [title]="!forConsole ? 'Payment Processing Volume' : null"
  [description]="!forConsole ? 'Enter your current or projected processing volumes' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="processing-volumes-step" class="w-full" [formGroup]="processingVolumesForm">
    <div class="items-left flex flex-col pt-2.5">
      <tilled-heading-h4>Annual Processing Volume</tilled-heading-h4>
      <tilled-label-l1 [secondary]="true" class="pt-4">
        On average, what is the total amount your company processes per year?
      </tilled-label-l1>
    </div>
    <mat-form-field appearance="standard" class="-mt-2 w-full">
      <mat-select
        data-test-id="annual-processing-volume"
        class="italic"
        placeholder=""
        [formControlName]="'annualProcessingVolume'"
      >
        <mat-option *ngFor="let range of volumeRanges" [value]="range.value">{{ range.viewValue }} </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="items-left flex flex-col pt-4">
      <tilled-heading-h4>Let’s estimate your Average Monthly Processing Volume</tilled-heading-h4>
      <tilled-paragraph-p4 [secondary]="true" class="pt-4">
        This is your average transaction amount multiplied by the average number of transactions a single Merchant
        processes each month.
      </tilled-paragraph-p4>
    </div>
    <div class="w-full">
      <tilled-input
        data-test-id="average-transaction-amount"
        class="w-full pt-2.5"
        name="averageTransactionAmount"
        label="What is your Average Transaction Amount?"
        tilledLabel="true"
        placeholder="$"
        mask="separator.0"
      ></tilled-input>
    </div>

    <tilled-input
      data-test-id="average-monthly-transactions"
      class="-mt-2 w-full"
      name="averageMonthlyTransactions"
      label="On average, how many transactions do you process each month?"
      tilledLabel="true"
      mask="separator.0"
    ></tilled-input>

    <div class="grid w-full grid-cols-2 py-4">
      <div class="rows-1 flex flex-col self-center">
        <tilled-label-l1 [secondary]="true"> Average Monthly Processing </tilled-label-l1>
        <tilled-paragraph-p4 [secondary]="true" class="pt-2">
          Your average monthly processing volume is:
        </tilled-paragraph-p4>
      </div>
      <div class="rows-1 justify-self-start pt-6 md:-ml-8">
        <outline-input
          data-test-id="average-monthly-processing"
          width="w-30"
          name="averageMonthlyVolume"
          showCurrency="'true'"
          inputTextStyle="mb-2 text-tilled-primary py-1"
          mask="separator.2"
          [value]="
            (
              processingVolumesForm.value.averageTransactionAmount *
              processingVolumesForm.value.averageMonthlyTransactions
            ).toLocaleString('en-US', { useGrouping: true })
          "
        >
        </outline-input>
      </div>
    </div>
  </form>
</merchant-app-card>
